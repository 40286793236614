













































































































































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Watch } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, numeric, max, min, required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { IRegisterAccount } from '@/types/types';

extend('email', {
  ...email,
  message: 'Email is not valid',
});
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: 'Phone number cannot be less than 10',
});
extend('max', {
  ...max,
  message: 'Phone number cannot be more than 13',
});

const authModule = namespace('auth');

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends mixins(WidgetMixins) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @authModule.Action('registerAsDeveloper') actionRegister: any;
  @authModule.Getter('getSetUpResponse') setUpResponse!: IRegisterAccount;

  // onboard customer
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';

  // verify account
  code: string = '';

  password: string = '';
  confirmPassword: string = '';

  acceptPrivacyPolicy: boolean = true;
  description: string = '';
  location: string = '';
  bg: string = require('@/assets/images/blob-scene-haikei.svg');
  show: boolean = false;

  business = {
    name: '',
    email: '',
    phone: '',
    description: '',
    location: '',
    isBusinessReseller: false,
  };

  fieldState: any = {
    newPassword: false,
    confirmPassword: false,
  };

  changeState(state: string): void {
    this.$set(this.fieldState, state, !this.fieldState[state]);
  }

  @Watch('setUpResponse')
  onSetUpResponseChange(payload: IRegisterAccount): void {
    console.log(payload);
    switch (payload.setUpStatus) {
      case 'VERIFY_ACCOUNT':
        this.step = 2;
        break;
      case 'SETUP_PASSWORD':
        this.step = 3;
        break;
      case 'COMPLETED':
        setTimeout(() => {
          this.$router.push({ name: 'accounts.signin' });
        }, 3000);
    }
  }

  step = 1;
  register(): void {
    switch (this.step) {
      case 1:
        this.$store.dispatch('auth/registerAccount', {
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          userType: 'PERSONAL',
        });
        break;
      case 2:
        this.$store.dispatch('auth/verifyAccount', {
          token:
            this.$route.params.token ?? this.setUpResponse.verifyAccountToken,
          code: this.code,
        });
        break;
      case 3:
        if (this.password.trim() !== this.confirmPassword.trim()) {
          this.$store.dispatch('snackBarMessage', "Password doesn't match", {
            root: true,
          });
          this.$store.dispatch('snackBarVisibility', true, { root: true });
          return;
        }
        this.$store.dispatch('auth/setAccountPassword', {
          token:
            this.$route.params.token ?? this.setUpResponse.setPasswordToken,
          password: this.password,
          confirmPassword: this.confirmPassword,
        });
        break;
    }
    // if (this.step > 1) {
    //   if (this.password.trim() !== this.confirmPassword.trim()) {
    //     this.$store.dispatch('snackBarMessage', "Password doesn't match", {
    //       root: true,
    //     });
    //     this.$store.dispatch('snackBarVisibility', true, { root: true });
    //     return;
    //   }
    //   let payload = {
    //     name: this.name,
    //     email: this.email,
    //     phone: this.phone,
    //     password: this.password,
    //     isBusiness: true,
    //     business: { ...this.business },
    //     acceptPrivacyPolicy: this.acceptPrivacyPolicy,
    //   };

    //   this.actionRegister(payload);
    // } else {
    //   this.step++;
    // }
  }
}
