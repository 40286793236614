var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"height":"100%"},attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 full-width flex-wrap flex-shrink-0 align-content-center justify-center"},[_c('div',{staticClass:"py-3 px-3 login-logo-container"},[_c('img',{staticClass:"cursor-pointer animate__animated animate__fadeIn login-logo-size pa-2",attrs:{"src":require("@/assets/logo.jpeg"),"alt":"National Commission On Culture"},on:{"click":function($event){return _vm.$router.push({ name: 'accounts.signin' })}}})]),_c('div',{staticClass:"rounded-sm ma-3 pl-4 pr-4 pb-6 animate__animated animate__fadeIn",staticStyle:{"border-radius":"8px !important","max-width":"500px"}},[_c('h2',{staticClass:"ft text-center text-center font-weight-bold"},[_vm._v(" Get started for free. ")]),_c('p',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Be up-to-date with the latest cultural events and activities happening around the country ")]),_c('ValidationObserver',{staticClass:"pt-2",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mr-lg-2 text-sm mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"First Name","error-messages":errors[0]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm mr-lg-2 mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"Last Name","error-messages":errors[0]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"phoneNumber","rules":"required|numeric|min:10|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm mr-lg-2 mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"Phone Number","error-messages":errors[0]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)]}}],null,true)})],1)])]),_c('v-window-item',{attrs:{"value":2}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2",attrs:{"name":"code","rules":_vm.step > 1 ? 'required|numeric|max:5' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mb-3 font-weight-medium text-sm",attrs:{"type":"text","outlined":"","label":"Verification Code","error-message":errors[0],"hint":'Enter your verification code here ..',"autofocus":true},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)]}}],null,true)})],1)])]),_c('v-window-item',{attrs:{"value":3}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2",attrs:{"name":"newPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.newPassword ? 'text' : 'password',"placeholder":"Enter a new password ","label":"New Password","append-icon":_vm.fieldState.newPassword
                            ? 'visibility_off'
                            : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('newPassword')}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2",attrs:{"name":"confirmPassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":_vm.fieldState.confirmPassword ? 'text' : 'password',"placeholder":"Confirm your new password ","label":"Confirm New Password","append-icon":_vm.fieldState.confirmPassword
                            ? 'visibility_off'
                            : 'visibility',"outlined":"","error-messages":errors[0],"autofocus":true},on:{"click:append":function($event){return _vm.changeState('confirmPassword')}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)})],1)])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","color":"primary","disabled":_vm.step === 1,"depressed":"","fab":""},on:{"click":function($event){_vm.step--}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("chevron_left")])]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","large":"","loading":_vm.isLoading,"disabled":_vm.step > 1 ? !_vm.acceptPrivacyPolicy || _vm.isLoading : false,"depressed":""}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v(_vm._s(_vm.step > 2 ? 'Complete Registration' : 'Proceed'))])])],1)],1)]}}])}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pt-6 pb-4"},[_c('router-link',{attrs:{"to":"/signin"}},[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Do you have an account?")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('span',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Signing up for an NCC account means you agree to the "),_c('router-link',{attrs:{"to":"/privacy-policy"}},[_c('span',{staticClass:"ft"},[_vm._v("Privacy Policy")])]),_vm._v(" and "),_c('router-link',{attrs:{"to":"/terms"}},[_c('span',{staticClass:"ft"},[_vm._v("Terms of Service")])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }